const path = "/api/v1/";
const TOKEN_KEY = "tradeworks_user_token";
const queryString = window.location.href;
console.log("queryString", queryString);
const SERVER_URL = queryString.includes("beta")
  ? "https://tra-spack-prd.azurewebsites.net/"
  : queryString.includes("twwstage") || queryString.includes("localhost")
  ? "https://tra-spack-stage.azurewebsites.net/"
  : "https://tra-spack-prd.azurewebsites.net/";
const MAX_INPUT_COUNT = 300;

const BASEURL = {
  URL: queryString.includes("beta")
    ? "https://beta.thetrade.works/"
    : queryString.includes("twwstage")
    ? "http://twwstage.franklinreport.com/"
    : "https://trade-works.com/",
};

const SERVER_URL_LOOKUP = queryString?.includes("beta")
  ? "https://tra-lookups-prd.azurewebsites.net"
  : queryString?.includes("twwstage") || queryString.includes("localhost")
  ? "https://tra-lookups-stage.azurewebsites.net"
  : "https://tra-lookups-prd.azurewebsites.net";

const URI = {
  JOINUS: `${path}joinus`,
  LOGIN: `${path}login`,
  FORGOT_PWD: `${path}forgot-password`,
  RESET_PASSWORD: `${path}reset-password`,
  SEND_THANKS: `${path}admin-thanks-mail`,
  REQUEST_ACCESS: `${path}request-access`,
};

const LOOKUP_URI = {
  POST_FEEDBACK: `${path}post-feedback`,
  TRADE_LOCATIONS: `${path}trade-locations`,
};

export {
  URI,
  SERVER_URL,
  TOKEN_KEY,
  MAX_INPUT_COUNT,
  BASEURL,
  LOOKUP_URI,
  SERVER_URL_LOOKUP,
};
