import React, { useState, useEffect } from "react";
import { Grid, InputAdornment } from "@material-ui/core";
import { get } from "lodash";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styleSheet from "../styles/login_styles";
import Text from "../../components/common/ui_kit/text";
import ShowPassword_Icon from "../../components/data_display/icons/ShowPassword";
import Close_Icon from "../../components/data_display/icons/Close";
import CustomButton from "../../components/navigations/custom_buttons";
import {
  ATLEAST_ONE_CAPITAL_LETTER,
  NUM_OR_SYMBOL,
  PASSWORD_REGEX,
  REGEX_EMIAL_END_WITH_DOT,
  REGEX_EMAIL,
} from "../../validations/validations";
import strings from "../../utilities/strings";
import CustomInputLabel from "../../components/data_display/custom_label";
import HidePassword_Icon from "../../components/data_display/icons/HidePassword";
import Ribbon from "../../components/data_display/icons/Ribbon";
import CustomCheckbox from "../../components/inputs/custom_checkbox";
import CustomTextField from "../../components/inputs/custom_textfields";
import CustomLink from "../../components/navigations/custom_links";
import CustomModal from "../../components/inputs/custom_modal";
import { JoinUsActions, LoginActions } from "../../redux/actions";
import { color } from "../../utilities/themes";
import PublicProfileContext from "../../components/Modals/success_modal/public_profile";

function Login(props) {
  const [values, setValues] = useState({ email: "", password: "", errors: {} });
  const [openIsPublicModal, SetOpenIsPublicModal] = useState(false);
  const {
    loggingIn,
    loginError,
    classes,
    openJoinusModal,
    signUpError,
    errorMessage,
    emailId,
    clearError,
    toggleModal,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const loginErr = useSelector((state) => state.Login.loginError);

  useEffect(() => {
    if (signUpError) {
      setValues({ ...values, email: emailId });
    }
    return () => {
      clearError();
    };
  }, []);
  const hasEightChars = (password) => {
    return password.length >= 8;
  };

  const hasCapitalLetter = (password) => {
    return ATLEAST_ONE_CAPITAL_LETTER.test(password);
  };

  const hasNumberOrSymbol = (password) => {
    return NUM_OR_SYMBOL.test(password);
  };

  const onEmailSuggestionChecked = (value) => (e) => {
    handleValidation("email", value);
    setValues({ ...values, email: value, showEmailSuggestion: false });
  };

  const handleValidation = (name, value) => {
    if (name === "email") {
      if (!value) {
        // IF NO VALUE RETURNING ERROR TRUE
        const { errors } = values;
        errors[name] = { error: true, touched: true };
        setValues({ ...values });
        return;
      }
      const checkType = PASSWORD_REGEX.test(value);
      if (checkType) {
        // NUMBER TYPE VALIDATION
        return;
      } else {
        // EMAIL TYPE VALIDATION
        const regexTest = REGEX_EMAIL.test(value);
        if (!regexTest) {
          const { errors } = values;
          errors[name] = { error: true, touched: true };
          setValues({ ...values });
        } else {
          const { errors } = values;
          errors[name] = { error: false, touched: true };
          setValues({
            ...values,
          });
        }
        return;
      }
    }
    if (name === "password") {
      const check1 = hasEightChars(value);
      const check2 = hasCapitalLetter(value);
      const check4 = hasNumberOrSymbol(value);
      if (check1 && check2 && check4) {
        const { errors } = values;
        errors[name] = { error: false, touched: true };
        setValues({ ...values });
        return;
      } else {
        const { errors } = values;
        errors[name] = { error: true, touched: true };
        setValues({ ...values });
        return;
      }
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      const checkType = PASSWORD_REGEX.test(value);
      if (checkType) {
        // NUMBER TYPE VALIDATION.
        setValues({ ...values, [name]: value });
        return;
      } else {
        // EMAIL TYPE VALIDATION.
        const showSuggestion = REGEX_EMIAL_END_WITH_DOT.test(value);
        setValues({
          ...values,
          [name]: value,
          showEmailSuggestion: showSuggestion,
        });
        return;
      }
    }
    if (name === "password") {
      setValues({ ...values, [name]: value });
    }
  };

  const handleClickShowPassword = () => {
    const { showPassword } = values;
    setValues({ ...values, showPassword: !showPassword });
  };

  const hasFormErrors = () => {
    const { errors, email, password } = values;
    const errs = Object.values(errors).map((each) => each.error);
    if (!errs.includes(true) && email && password) {
      return false;
    }
    return true;
  };

  const closeModal = () => {
    setValues({ ...values, email: "", password: "", errors: {} });
    dispatch(LoginActions.clearLogInProps());
    props.closeModal();
  };

  const closePublicModal = () => {
    const data = {
      email: email,
    };
    dispatch(
      JoinUsActions.requestAccess(data, (resp) => {
        const error = get(resp, "data.error.error");
        if (!error) {
          history.push("/");
        }
      })
    );
    SetOpenIsPublicModal(false);
  };

  const submitForm = () => {
    dispatch(
      LoginActions.login({ ...values }, (res) => {
        const uType = get(res, "user.userType", "");
        if (!res.user.isPublic) {
          SetOpenIsPublicModal(true);
          return;
        }
        if (uType === "Admin") {
          if (res.token) {
            localStorage.setItem("admintoken", res.token);
            localStorage.setItem("userType", "admin");
            history.push(`/superadmin/${res.token}`);
          }
        } else if (uType === "Individual") {
          if (res.token) {
            localStorage.setItem("lpacktoken", res.token);
            localStorage.setItem("userType", uType ? "lpack" : "cpack");
            history.push(`/lpack/profile/${res.token}`);
          }
        } else {
          if (res.token) {
            localStorage.setItem("cpacktoken", res.token);
            history.push(`/cpack/${res.token}`);
          }
        }
      })
    );
  };

  const openForgotPassword = () => {
    toggleModal("displayLogin", "showFindAccount");
  };

  const openForgotEmail = () => {
    toggleModal("displayLogin", "showFindEmail");
  };

  const { showPassword, showEmailSuggestion, email, password, errors } = values;
  return (
    <React.Fragment>
      <CustomModal
        open
        aria-labelledby="modal-title"
        disablePortal
        aria-describedby="modal-description"
        disableAutoFocus
      >
        <Grid container justify="center" className={classes.popup}>
          <Grid container justify="flex-end" md={12}>
            <CustomButton
              onClick={props.closePopup}
              className={classes.closeBtn}
            >
              <Close_Icon onClick={closeModal} />
            </CustomButton>
          </Grid>
          <Grid container justify="center" md={12}>
            <img src="/assets/images/Sign In Logo.svg" alt="" />
          </Grid>
          {signUpError && (
            <div className={classes.signUpErrorMessage}>{errorMessage}</div>
          )}
          <Grid className={classes.formContainer}>
            <form
              className={classes.form}
              autoComplete="off"
              onSubmit={() => submitForm()}
            >
              <div>
                {showEmailSuggestion && (
                  <div className={classes.auto_email}>
                    <CustomCheckbox
                      label={`Do you mean ${email}com?`}
                      labelPlacement="start"
                      text_transform="none"
                      onChange={onEmailSuggestionChecked(`${email}com`)}
                    />
                    <CustomInputLabel
                      is_input_label={true}
                      color={color.primary_palette.black}
                      label_name="Yes"
                    />
                  </div>
                )}
                <p className={classes.errorText} style={{ margin: 0 }}>
                  {loginError && loginError.error
                    ? loginError.error.message.message
                    : null}
                  {get(loginErr, "error.code", "") === 1029 &&
                    "We do not have this member in our database"}
                </p>
                <CustomTextField
                  label={
                    !showEmailSuggestion &&
                    !signUpError &&
                    (!loginError || !loginError.error) &&
                    get(loginErr, "error.code", "") !== 1029
                      ? strings.login.titles.email_phone_username
                      : null
                  }
                  name="email"
                  value={email}
                  onChange={onInputChange}
                  onBlur={(e) =>
                    handleValidation(e.target.name, e.target.value)
                  }
                  className={`${
                    !get(errors, "email.touched", false)
                      ? classes.inp_initial
                      : get(errors, "email.error", false)
                      ? classes.inp_error
                      : classes.inp_success
                  } ${classes.textFieldSuccess}`}
                />
              </div>

              <div>
                {get(loginErr, "error.message.code", "") === 1001 && (
                  <Text
                    family="gillsans_sb"
                    size={10}
                    color={color.primary_palette.christmas_red}
                  >
                    Password does not match up. Try again!
                  </Text>
                )}
                <CustomTextField
                  label={
                    get(loginErr, "error.message.code", "") !== 1001
                      ? strings.join_us.titles.password
                      : null
                  }
                  onChange={onInputChange}
                  onKeyUp={(e) =>
                    handleValidation(e.target.name, e.target.value)
                  }
                  value={password}
                  name="password"
                  className={`${
                    !get(errors, "password.touched", false)
                      ? classes.inp_initial
                      : get(errors, "password.error", false)
                      ? classes.inp_error
                      : classes.inp_success
                  } ${classes.textFieldSuccess}`}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        tabIndex="-1"
                        aria-label="toggle password visibility"
                        className={classes.password_icon}
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? (
                          <ShowPassword_Icon
                            color={color.primary_palette.franklin_purple}
                            style={{
                              fontSize: "1.3125rem",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <HidePassword_Icon
                            style={{
                              fontSize: "1.3125rem",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <Grid
                container
                item
                justify="flex-start"
                className={classes.rememberMe}
              >
                <CustomCheckbox
                  label="Remember me"
                  className={classes.rememberMeText}
                />
              </Grid>
              {/* {signUpError && (
                <Grid container item justify="center" alignItems="center">
                  <a className={classes.forgotPasswordLarge}>
                    Forgot your password?
                  </a>
                </Grid>
              )} */}
              <Grid container item justify="center" alignItems="center">
                <CustomButton
                  variant="outlined"
                  color="primary"
                  type="submit"
                  size="large"
                  custom_type="submit"
                  className={classes.submit_button}
                  onClick={() => submitForm()}
                  disabled={hasFormErrors()}
                  endIcon={
                    !hasFormErrors() && (
                      <Ribbon
                        color={loggingIn ? "" : "primary"}
                        style={{
                          fontSize: "47px",
                          marginTop: "-3px",
                        }}
                      />
                    )
                  }
                >
                  {!signUpError
                    ? strings.landing_page.titles.welcome
                    : "SIGN IN"}
                </CustomButton>
              </Grid>
            </form>
          </Grid>
          {/* {!signUpError ||  && ( */}
          <Grid
            container
            justify="space-between"
            className={classes.footer_spacing}
          >
            <Grid
              item
              xs={6}
              className={classes.footerContainer}
              style={{
                color:
                  get(loginError, "error", false) ||
                  get(loginErr, "error.code", "") === 1029 ||
                  get(loginErr, "error.message.code", "") === 1001 ||
                  showEmailSuggestion
                    ? color.primary_palette.christmas_red
                    : "",
              }}
            >
              {strings.login.titles.forgot_your} <br />
              <CustomLink
                underline="always"
                color={
                  get(loginErr, "error.message.code", "") === 1001 ||
                  (loginError && loginError.error)
                    ? "error"
                    : ""
                }
                onClick={openForgotPassword}
              >
                <label className={classes.cursor}>
                  {strings.login.titles.password}
                </label>
              </CustomLink>{" "}
              {strings.login.titles.or}{" "}
              <CustomLink
                underline="always"
                color={
                  get(loginErr, "error.message.code", "") === 1001 ||
                  (loginError && loginError.error)
                    ? "error"
                    : ""
                }
                onClick={openForgotEmail}
              >
                <label
                  style={{ whiteSpace: "nowrap" }}
                  className={classes.cursor}
                >
                  {strings.login.titles.credentials}?
                </label>
              </CustomLink>
            </Grid>
            <Grid item xs={5} className={classes.footerContainer}>
              <p style={{ margin: 0 }}>{strings.login.titles.not_member}</p>
              <CustomLink underline="always" onClick={openJoinusModal}>
                <label className={classes.cursor}>
                  {strings.login.titles.join_here}!
                </label>
              </CustomLink>
            </Grid>
          </Grid>
          {/* )} */}
        </Grid>
      </CustomModal>
      {openIsPublicModal && (
        <Grid container item justify="center" alignItems="center">
          <PublicProfileContext open={true} closePModal={closePublicModal} />{" "}
        </Grid>
      )}
    </React.Fragment>
  );
}

export default styleSheet(Login);
