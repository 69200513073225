import { Grid, Modal } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get } from "lodash";

import strings from "../../../utilities/strings";
import tradework_theme, {
  color,
  font_sizes,
  pxToRem,
} from "../../../utilities/themes";
import Close_Icon from "../../data_display/icons/Close";
import CustomButton from "../../navigations/custom_buttons";
import { Route, withRouter } from "react-router-dom";
import { TOKEN_KEY } from "../../../constants";
import CustomModal from "../../inputs/custom_modal";
import { JoinUsActions } from "../../../redux/actions";

const useStyles = {
  popup: {
    backgroundColor: color.secondary_palette.grays.fafa_gray,
    padding: "1.25rem",
    border: `2px solid ${color.primary_palette.franklin_purple}`,
    width: "500px",
    margin: "0 auto",
    "&:focus": {
      outline: "none",
      border: `2px solid ${color.primary_palette.franklin_purple}`,
    },
  },
  welcomeTextAlign: {
    textAlign: "center",
    color: color.primary_palette.black,
    fontSize: font_sizes.font_35,
    fontFamily: "Zapfino",
    textTransform: "capitalize",
    height: "5.25rem",
  },
  cpackWelcomeTextAlign: {
    textAlign: "center",
    color: color.primary_palette.black,
    fontSize: font_sizes.font_40,
    ...tradework_theme.typography.styles.avenir_sb,
    textTransform: "uppercase",
  },
  cpackUserNameAlign: {
    textAlign: "center",
    color: color.user_green,
    fontSize: "34px",
    ...tradework_theme.typography.styles.avenir_sb,
    textTransform: "uppercase",
    marginBottom: pxToRem(31),
  },
  textPurse: {
    textAlign: "center",
    fontSize: "20px",
    ...tradework_theme.typography.styles.gillsans_light,
    color: color.primary_palette.black,
  },
  cpack_desc: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: 500,
    ...tradework_theme.typography.styles.avenir_sb,
    color: color.primary_palette.black,
  },
  cpack_img: {
    // paddingBottom: "20px",
    paddingTop: "12px",
    // width: "94%",
    height: "120px",
    width: "157px",
  },
  btn: {
    border: `1px solid #128040`,
    width: "160px",
    height: "36px",
    borderRadius: "20px",
    backgroundColor: color.primary_palette.white,
    fontSize: "18px",
    color: color.user_green,
    ...tradework_theme.typography.styles.gillsans_sb,
    marginTop: "50px",
    marginBottom: "42px",
  },
  success_popup: {
    "& .MuiGrid-root": {
      "&:focus": {
        outline: "none !important",
        border: "0 !important",
      },
    },
  },
};

class PublicProfileContext extends Component {
  constructor(props) {
    super(props);
    let isCompany = false;
    const loginData = get(this.props.loginData, "data", false);
    if (get(loginData, "user.userType", false) === "Company") {
      isCompany = true;
    }
    this.state = {
      closeIcon:
        this.props && this.props.closeIcon ? this.props.closeIcon : false,
      isCompany: isCompany,
    };
  }

  closeModal = () => {
    this.setState({
      closeIcon: false,
    });
    const userType = get(this.props.loginData, "data", false);
    if (get(userType, "user.userType", false) === "Individual") {
      localStorage.setItem("lpacktoken", get(userType, "token", ""));
    }
    if (get(userType, "user.userType", false) === "Company") {
      localStorage.setItem("cpacktoken", get(userType, "token", ""));
    }
    this.props.actions.closeSuccessModal();
  };

  handle_click_registration = () => {
    this.closeModal();
  };

  closePublicModal = () => {
    this.props.closePModal()
  }

  render() {
    const { classes, open, loginData } = this.props;
    const { closeIcon, isCompany } = this.state;
    return (
      <>
        {
          <CustomModal
            open={open}
            aria-labelledby="modal-title"
            disablePortal
            aria-describedby="modal-description"
            disableAutoFocus
            className={classes.success_popup}
          >
            <Grid container className={classes.modalStyle}>
              {/* <Grid md={4}></Grid> */}
              <Grid container justify="center" className={classes.popup}>
                {/* <Grid container justify="flex-end" md={12}>
                  <CustomButton onClick={this.props.closePopup}>
                    <Close_Icon onClick={this.closeModal} />
                  </CustomButton>
                </Grid> */}
                {/* Cpack Modal */}
                {isCompany ? (
                  <Grid container justify="center" md={12}>
                    <Grid
                      container
                      md={12}
                      justify="center"
                      className={classes.cpackWelcomeTextAlign}
                    >
                      {strings.success_modal.titles.welcome}
                    </Grid>
                    <Grid
                      container
                      md={12}
                      justify="center"
                      className={classes.cpackUserNameAlign}
                    >
                      {`${localStorage.getItem(
                        "firstName"
                      )} ${localStorage.getItem("lastName")}`}
                    </Grid>
                    <Grid
                      md={8}
                      justify="center"
                      className={classes.cpack_desc}
                    >
                      {strings.success_modal.titles.cpack_desc}
                    </Grid>
                    <Grid
                      md={8}
                      justify="center"
                      className={classes.cpack_desc}
                    >
                      {strings.success_modal.titles.cpack_desc_2}
                    </Grid>
                    <Grid
                      md={8}
                      justify="center"
                      className={classes.cpack_desc}
                    >
                      {strings.success_modal.titles.cpack_desc_3}
                    </Grid>
                    <Grid container justify="center" md={12}>
                      <img
                        className={classes.cpack_img}
                        // src="../assets/images/Good-Citizen-Animation.gif"
                        src="../assets/images/c_success.png"
                        alt=""
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container justify="center" md={12}>
                    <Grid container justify="center" md={12}>
                      <img
                        src="../assets/images/success.gif"
                        alt=""
                        style={{ width: "250px" }}
                      />
                      {/* <Grid
                        container
                        justify="center"
                        className={classes.welcomeTextAlign}
                      >
                        {strings.success_modal.titles.welcome}
                      </Grid> */}
                      <img
                        src="../assets/images/Welcome.svg"
                        alt=""
                        style={{
                          width: "208px",
                          marginTop: "45px",
                          marginBottom: "15px",
                        }}
                      />
                    </Grid>
                    <Grid
                      md={12}
                      justify="center"
                      className={classes.textPurse}
                    >
                      Your Profile has already been created. <br />
                      Please contact Alex <br />
                      at team@trade-works.com, 866.960.9100
                    </Grid>
                  </Grid>
                )}
                <Grid container justify="center" md={12}>
                  <CustomButton
                    color="primary"
                    className={classes.btn}
                    onClick={this.closePublicModal}
                  >
                    OK!
                  </CustomButton>
                </Grid>
                <Grid></Grid>
              </Grid>
              {/* <Grid md={4}></Grid> */}
            </Grid>
          </CustomModal>
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginData: state.JoinUs.joinUsFormData.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...JoinUsActions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles, { withTheme: true })(PublicProfileContext)));
