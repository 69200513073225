const strings = {
  general: {
    titles: {},
    messages: {},
    errors: {},
  },
  image_text: {
    titles: {},
    alt_text: {
      cart_icon: "cart icon",
      happy_arrow: "Happy Arrow",
    },
    errors: {},
  },
  modals: {
    titles: {
      Send: "Send",
      message_to: "Message to",
    },
  },
  success_modal: {
    titles: {
      welcome: "welcome",
      pursue_your_dream_job:
        "This is Benjamin, your traveling companion. He will",
      pursue_your_dream_job1: "grow with you as you pursue your Dream Job",
      begin: "let's begin",
      cpack_desc: "Find your Best Employee Ever",
      cpack_desc_2: "&",
      cpack_desc_3: "Contribute to the Trade Community",
    },
    messages: {},
    errors: {},
  },
  join_us: {
    titles: {
      tradeWorks: "TradeWorks",
      firstName: "First Name",
      lastName: "Last Name",
      email_address: "Email Address",
      password: "Password",
      phone_number: "Phone Number",
      i_am: "I'm a:",
      you_are_a: "JOIN NOW AS:",
      company: "COMPANY",
      individual: "INDIVIDUAL",
      join: "JOIN",
      account_exists: "Already have a TradeWorks account?",
      sign_in: "Sign In",
      sign_up_fr: "By signing up, you agree to TW's",
      terms_services: "Terms of Service",
      profile_name: "Profile Name",
      popover_description:
        "This doesn’t have to be your legal name but what you choose to be called in the job market.",
    },
    messages: {
      option_login: "Optional for faster Sign In",
      password_rule: "8 characters. 1 Upper. 1 number or symbol",
      eight_chars: "8 characters",
      one_letter: "1 Uppercase letter",
      number_symbol: "1 number or symbol",
      success_message: "Welcome!. Pursue your dream job with TradeWorks.",
    },
    errors: {
      firstName_required: "First Name is required",
      firstName_min_max: "Minimum 1 Character or Max 35 Characters",
      firstName_max: "Minimum 1 Character or Max 35 Characters",
      lastName_required: "Last Name is required",
      lastName_min_max: "Minimum 1 Character or Max 35 Characters",
      lastName_max: "Minimum 1 Character or Max 35 Characters",
      email_address_required: "Email Address is required",
      invalid_email_address: "Please enter valid email address",
      password_required: "Password is required",
      password_max: "Password cannot be more than 20 characters",
      invalid_phone_number: "Invalid Phone Number",
    },
  },
  extra_buttons: {
    type1_form: {
      title: {
        header_text: "• Research •",
        Title: "Title",
        Institution: "Institution",
        From: "From",
        To: "To",
        city: "City, State or ZIP",
        not_usa: "Not USA?",
        Research_Accomplishments: "Research Accomplishments",
      },
    },
    interest_hobbies: {
      title: {
        header_text: "• Interests & Hobbies •",
        step_1: "Step 1",
        Choose_format: "Choose Format",
        Bullet_points: "Bullet Points",
        Bullet_points_1: "• Bauhaus Typography",
        Bullet_points_2: "• Scooner Sailing",
        Bullet_points_3: "• Landscapes of Capability Brown",
        paragraph: "Paragraph",
        paragraph_des:
          "Bauhaus Typography, Scooner Sailing, Landscapes of Capability Brown",
        icon: "Icon Display",
        step_2: "Step 2",
        Latest_obssesions: "Latest Obssesions",
        Latest_obssesions_sub1: "Pick an interest below, or add your own",
        Chose_interest: "Chose Interest",
        Latest_obssesions_sub2:
          "Description necessary if using bullet points or paragraph format ",
        placholder1: "Sailed around the world in 40 days",
        field_des:
          "Must include name of interest as title will not show in <paragraph> form ",
        update_icon: "Update Icon",
      },
    },
  },
  login: {
    titles: {
      forgot_your: "Forgot your",
      password: "password",
      or: "or",
      email: "email",
      credentials: "email",
      not_member: "Not yet a member?",
      join_here: "Join Here",
      email_phone_number: "Email",
      email_phone_username: "Email",
    },
    errors: {
      email_phone_required: "Email is required",
      invalid_email_phone: "Please enter valid email",
      email_not_found: "We do not have this member in our database",
      password_dosent_match: "Password does not match, Try again!",
    },
  },
  landing_page: {
    titles: {
      log_in: "Sign In",
      join_us: "JOIN US",
      find_best_candidates: "FIND THE VERY BEST CANDIDATES",
      architecture_candidates: "Architecture Candidates",
      interior_design: "Interior Design Prospects",
      plumbing_heros: "Plumbing Heros",
      contractor_counsel: "Contractor Counsel",
      welcome: "WELCOME",
      connect_with_community: "CONNECT WITH THE TRADE COMMUNITY",
      get_found: "GET FOUND",
      job_title: "Search by Title, Company, Job Summary Keywords",
      highlight_apprenticeships: "Highlight Apprenticeships",
      show_skills: "Show the world your skills",
      find_talent: "FIND TALENT",
      skills_jobs: " Search by Company Name, Skills, Trade or Level   ",
      appropriate_candidates: "Ping the most appropiate candidates",
      post_resume: "POST YOUR PROFILE",
      post_a_job: "Post a Job",
      footer_text: "TradeWorks® Beta 1.0",
      hiring_companies: "HIRING COMPANIES",
      architectual_firms: "Architectual Firms",
      interior_design_firms: "Interior Design Firms",
      plumbing_outfits: "Plumbing Outfits",
      pursue_your_dream_job: "PURSUE YOUR DREAM JOB",
      architecture_positions: "Architecture Positions",
      interior_design_careers: "Interior Design Careers",
      plumbing_gigs: "Plumbing Gigs",
      contractor_openings: "Contractor Openings",
      placeholder_location: "city, state, zip",
      feedback_logo_text: "Beta Testin Feedback Logo",
    },
    messages: {},
    errors: {},
  },
  loggin_header: {
    titles: {
      current_position: "CURRENT POSITION:",
      welcome: "WELCOME",
      get_found: "GET FOUND",
      find_talent: "FIND TALENT",
      search_jobs: "SEARCH JOBS",
      my_profile: "MY PROFILE",
      active_employers: "ACTIVE EMPLOYERS",
      post_jobs: "POST JOBS",
      manage_applicants: "MANAGE APPLICANTS",
      discover_candidates: "DISCOVER CANDIDATES",
      people: "People",
      job_title_name_company: "Job Title, Name, Company",
      city_state_zip: "City, State or Zip",
      advanced_search: "Advanced Search",
    },
    messages: {},
    errors: {},
  },
  wizard: {
    titles: {
      completed: "All steps completed",
      Reset: "Reset",
    },
    messages: {},
    errors: {},
  },
  add_trade: {
    titles: {
      thank_you: "THANK YOU FOR JOINING",
      develop: "Let’s develop your professional presence",
      main_trade: "MAIN TRADE",
      what_you_do: "What you ",
      do: " do",
      what_you_do_remain:
        ", not where you work.  You may add other trades later",
      location: "YOUR LOCATION",
      currently_reside: "Where you currently reside. City,State or ZIP",
      not_usa: "Not USA?",
      student: "ARE YOU A STUDENT?",
      city_zip: "City or ZIP code ",
      Architecture: "Architecture",
      new_york: "New York, NY",
    },
    messages: {},
    errors: {},
  },
  add_education: {
    titles: {
      add_education: "Add Education",
      edu_describe: "Describe your current or most relevant institution.",
      add_school: "You may add other schools later.",
      institution: "Institution",
      subtitle: "Optional Subtitle",
      city_state: "City/ State",
      from: "From",
      not_usa: "Not USA?",
      expected_graduation: "To (Expected Graduation)",
      program_level: "Program Level",
      degree: "Degree",
      Major: "Major",
      minor: "Minor/Concentration",
      headline: "Headline",
      make_headline: "Make this my headline",
      highlights: "Highlights and Coursework",
      now_displayed:
        "Now displayed: Student at Columbia School of Architecture, Planning ... ",
      previw_displayed: "Preview:  Architect, Engineer, Carpenter & MyOwn",
      add_highlight: "Add Highlight",
      add_major: "Add Major",
      tag_me:
        "Please tag one or more majors, employers to more easily find you!",
      add_minor: "Add Minor",
      candidate: "Candidate, Master’s Degree in Planning",
      select_school: "Select or type your school",
      alpha_merit: "Alpha Ro Chi - Medal for Professional Merit",
      ex_architecture: "Ex: Architecture",
      ex_urban: "Ex: Urban Planning",
      highlight_professional: "Let’s develop your professional presence",
      school_logo: "School Logo",
      company_logo: "Company Logo",
      majorPrediction: "Do you mean",
    },
    messages: {},
    errors: {},
  },
  add_exprience: {
    titles: {
      add_exprience: "add Experience",
      add_exprience_des:
        "What recent experience most closely aligns with your career goals?",
      company: "Company/Entity",
      company_name: "Your Company",
      entity: "Short Description of Entity",
      well_known: "(if not well known)",
      location: "Office Location",
      employment: "Duration",
      from: "From",
      month: "Month",
      year: "Year",
      to: "To",
      title: "Title",
      add_title: "Additional Title for this Experience",
      accomplishments: "Accomplishments",
      action: "Start each point with an ACTION verb. ",
      see_List: "See List",
      add_accomplishments: "Add Accomplishment",
      headline: "Headline",
      checkbox: "Make this my Profile Headline",
      headline_des:
        "Now displayed: Student at Columbia School of Architecture, Planning ... ",
      add_Media: "Add Related Media",
      add_link: "Add Link",
      add_file: "Add File",
      current_postion: "Current Position?",
      highlight_professional: "HIGHLIGHT YOUR PROFESSIONAL PROWESS",
      join_trade: "Join the Trade Community",
      highlight_credential: "HIGHLIGHT YOUR CREDENTIALS",
      add_link_desc: "Maximum of 4 Media Links per Experience",
      add_link_desc2: "=  Pick 1-2 to display",
    },
    messages: {},
    errors: {},
  },
  add_file: {
    titles: {
      add_link: "ADD LINK",
      edit_link: "EDIT LINK",
      add_link_des: "This could be a website or a video link",
      link_image_error_text: "This URL has no main image",
      add_link_placeholder_URL: "Type URL address here (yoursite.com)",
      add_another_link: "Upload",
      add_link_label: "Link Title",
      add_link_label_placeholder:
        "This will autofill from site or enter your own title",
      Page_Description: "Page Description",
      textArea_placeholder: "Come from site too.",
      Btn_title: "CAPTURE",
      add_file: "ADD FILE",
      edit_file: "EDIT FILE",
      add_file_des: "This could be a jpg, png, svg.",
      step_1: "Step 1 : Find the graphic",
      step_2: "Step 2 : Describe your image",
      step_2_label: "Displayed Caption",
      step_2_placeholder: "The Salas Hacienda - Montañita, Ecuador",
      step_2_textAear: "File Description",
      step_2_textAear_placeholder:
        "This 10 acres property is located near the coastal town of Montañita, in Ecuador.  The Salas family was very clear about their desire of keeping their new beach recidence in the same local style of open areas, large windows, soft woods and earth tones. ",
      step_3: "Step 3: Optional Link (Choose one)",
      step_3_radio_1: "No Link",
      step_3_radio_2: "URL Address",
      step_3_radio_2_input: "www.SalasHacienda.com",
      step_3_radio_3: "Upload PDF or Doc",
      add_file_btn: "DISPLAY",
      add_previewed_link: "Your site will be previewed here",
      add_previewed_file: "UPLOAD  LOCAL IMAGE",
      add_your_image: "UPLOAD YOUR IMAGE",
    },
    messages: {},
    errors: {},
  },
  add_photoOp: {
    titles: {
      add_image_title: "Let’s add some images to your portfolio",
      add_image_des: "Profiles with headshots have a 27% more visibility",
      profile_link: "Upload Profile Photo or Logo",
      cover_link: "Upload Main Cover Photo",
      profile_text: "That’s a great profile picture!",
      profile_crop: "Let’s crop it to fit your portfolio",
      drag_img: "Drag the image to desired position.",
      drag_zoom: "Drag the zoom handle to change photo size.",
      choose_image: "SWAP IMAGE",
      drag_crop: "Drag the crop selection to desired position.",
      cover_text: "That’s a beautiful cover picture ",
      cover_right: "Let’s get the proportions right",
      back_text: "You can go back and change these at any time!",
      change_profile: "Change Profile Photo or Logo",
      change_cover: "Change Main Cover Photo",
      well_done: "Well done. Let's go check out your TW Profile!",
    },
    messages: {},
    errors: {},
  },
  tricks_of_trades_list: {
    titles: {
      active_verbs_alphabetical: "Active Verbs, Alphabetical",
      active_verbs_function: "Active Verbs, by Function",
      additional_interests_guidelines: "Additional Interests Guidelines",
      average_vs_great_profile: "Average vs. Great Profile",
      education_highlight_examples: "Education Highlight Examples",
      extracurricular_guidelines: "Extracurricular Guidelines",
      grammar_guidelines: "Grammar Guidelines",
      key_profile_tips: "Key Profile Tips",
      language_levels: "Language Levels",
      PARS_method: "PARS Method",
      purpose_online_profile: "Purpose of a Online Profile",
      skills_summary_guidelines: "Skills Summary Guidelines",
      specific_language_coaching: "Specific Language Coaching",
      top_6_resume_mistakes: "Top 6 Resume Mistakes",
      your_profile_success_plan: "Your Profile Success Plan",
    },
    messages: {},
    errors: {},
  },
  tricks_of_trades: {
    titles: {
      tricks_trade: "Tricks of the Trade",
      purpose_of_a_profile: "Purpose of a Online Profile",
      primary_purpose: "Primary Purpose of your Online Profile:",
      primary_purpose_content:
        " To bolster your name and reputation.Also, to get you an interview if job seeking! Be brief. Be clear. Impress.",
      secondary_purpose: "Secondary Purpose:",
      secondary_purpose_content:
        " To serve as the basis for your upcoming interviews or industry discussions. You get to lead the conversation with your profile cues, be smart about this. Be accurate. Only include bullet points that you are capable of eloquently discussing.",
      your_profile_Success_plan: "Your Profile Success Plan",
      your_profile_Success_plan_content1:
        "On average, viewers will give your profile  ",
      your_profile_Success_plan_heading1:
        " 1. Be Concise, Quantitative & Memorable",
      your_profile_Success_plan_span1: "6 seconds or less",
      your_profile_Success_plan_subcontent1:
        " for the first read, and only another 2 or 3 minutes for a possible interview or contact possibility. Give them easy-to-convey numbers or notable experiences that make it easier to justify the extra attention.",
      your_profile_Success_plan_heading2: "2. Stay Focused",
      your_profile_Success_plan_content2:
        "Tailor the profile to your carreer goals, cutting extraneous information that may distract from that objective. Include any transferable skills that build toward this goal, including summer construction stints, AutoCAD renderings for your parents’ company or your personal sketchbook. This also distinguishes you.",
      your_profile_Success_plan_heading3: "3. Be Commercial:",
      your_profile_Success_plan_heading3_span:
        " You Must Answer This Viewer Question ",
      your_profile_Success_plan_content3:
        "“What can you do for me in the next six months?” They are looking for evidence that you can contribute very soon to their team or aspirations.",
      key_profile_tips: "Key Profile Tips",
      key_profile_tips_content:
        "As curated from years of recruiter feedback with Columbia University’s Architecture School and Dartmouth College’s Tuck Business School. ",
      Content: "Content",
      key_profile_tips_point1:
        "Be concise and focused. Each word should add value.",
      key_profile_tips_point2:
        "Do not include an Objective (unless highly specific job) or Personal Statement/Skills Summary (unless 10+ years of experience), as they take up valuable space.",
      key_profile_tips_point3:
        "Once you are at the graduate level or apprentice level, high school name is no longer relevant, unless it has networking power.",
      key_profile_tips_point4:
        "Report GPA only if 3.0 or higher (“3.0 good to go”) and only to one decimal place.",
      key_profile_tips_point5:
        "If degree is not completed, write “Earned xx/yy credits towards BA, Accounting.”",
      key_profile_tips_point6:
        "Use past tense for previous jobs and present tense for current job.",
      key_profile_tips_point7:
        "Experience is the heart of the profile. Most importantly, make sure each bullet point adds value and relates to the position to which you are applying. Include military, part-time and unpaid work. Avoid time gaps, if possible, especially if student or recent graduate. However, if you have 10+ years experience, there is no need to account for all your time, and no need to include bullets for all jobs.",
      key_profile_tips_point8:
        "Avoid personal pronouns (I) and jargon. Start each Work Experience bullet point with an Action Verb ",
      key_profile_tips_point8_link: " See List ",
      key_profile_tips_point9:
        "Avoid repeating words or concepts, especially the beginning Action Verbs.",
      key_profile_tips_point10: "Emphasize ",
      key_profile_tips_point10_bold: "results",
      key_profile_tips_point10_span:
        "—qualitative or quantitative—for additional impact.",
      key_profile_tips_point11:
        "Use years not months for full time jobs over a year in duration. Use season for internships (“Summer 2016”).",
      key_profile_tips_point12:
        "CVs are more inclusive than profiles, generally including research and publications, and are generally used for academic positions, not trade jobs.",
      key_profile_tips_point13:
        "Make sure everything is true, accurate, and easily explained. Among other things, the information is subject to detailed examination at the interview. Be very prepared to talk about these points at length.",
      key_profile_tips_point14:
        "Do not include photo, gender, age, marital status children on profile, or “references available upon request.”",
      key_profile_tips_point15:
        "Proof, proof, proof spelling and grammar! The have a friend or two proof read it.",
      format: "Format",
      key_profile_tips_format1: "Use readable size font (10 to 12).",
      key_profile_tips_format2:
        "Maximum six work experiences; exception only if 10+ years career.",
      key_profile_tips_format3:
        "Include white space for easy reading and for recruiter notes",
      key_profile_tips_format4: "Locations are generally flush right.",
      key_profile_tips_format5:
        "Work Experiences & Education items in reverse chronological order.",
      key_profile_tips_format6:
        "Use PDF when emailing the profile, to avaoid misalignment.",
      Importantly: " Importantly ",
      Importantly_content: "This is a work in continual progress.",
      average_vs_great_profile: "Average vs. Great Profile",
      average_vs_great_profile_subtitle:
        "Grab the Attention of the Hiring Manager and the Trade Community",
      average_vs_great_profile_span1: "Quantify",
      average_vs_great_profile_content1:
        " Detail your bullets with results. If someone else could have written this same bullet, it does not distinguish you. Rewrite it; it is a job description.",
      average_vs_great_profile_span2: "Your Impact",
      average_vs_great_profile_content2:
        " Did you do something better, more creative, more interesting than someone else? Or faster than it was done before? What was the impact of your work? Specifically state results with conviction! “Increased sales/reduced costs/decreased time by 5% over 10 weeks” packs more punch than just “increased sales.” If not precisely quantitative, describe in a general numeric way, such as “reduced project time by a third.” ",
      average_vs_great_profile_span3: "Context",
      average_vs_great_profile_content3:
        " Use context to communicate impact. “Managed the interior design build out of the One Vanderbilt Tower overseeing a budget of $1B and 65 of the firm’s 550 employees” is way better than “developed One Vanderbilt Tower design schematics.” ",
      average_vs_great_profile_span4: "Focus",
      average_vs_great_profile_content4:
        " Make sure the profile has a clear focus, highlighting the skills requested for the targeted job. Cut irrelevant information.",
      average_vs_great_profile_span5: "Passion",
      average_vs_great_profile_content5:
        " Don’t be boring or vague. Is it clear that I have not only the skills, but also the passion to succeed in the desired position?",
      average_vs_great_profile_span6: "No Typos! ",
      average_vs_great_profile_content6:
        "Just one typo reduces the chance of receiving an interview by 70%. Check the PDF version also for formatting kerfluffles. Review with friend or mentor",
      average_vs_great_profile_span7: "Update",
      average_vs_great_profile_content7:
        " This personal masterpiece is a constant work in progress. Always keep it updated.",
      top_6_profile_mistakes: "Top 6 Resume Mistakes",
      top_6_profile_mistakes_subtitle: "According to Harvard’s Career Office",
      top_6_profile_mistakes_point1: "Spelling and grammar errors ",
      top_6_profile_mistakes_point2:
        "Missing Email and Phone Numbers. Impossible with TW ",
      top_6_profile_mistakes_point2_span: "with TradeWorks",
      top_6_profile_mistakes_point3: "Not using active verbs ",
      top_6_profile_mistakes_point4:
        "Not well organized, concise, or easy to skim. TW fixes this.",
      top_6_profile_mistakes_point5:
        "Not demonstrating results, too descriptive ",
      top_6_profile_mistakes_point5_span: "Quantify your bullets with numbers!",
      top_6_profile_mistakes_point6: "Too long ",
      top_6_profile_mistakes_point6_span: " Impossible with TW",
      top_6_profile_mistakes_point1_span: " have three people check it!",
      top_6_profile_mistakes_key: "Key",
      top_6_profile_mistakes_key1:
        "It is well documented that most hiring managers spend less",
      top_6_profile_mistakes_key1_span: " 6 seconds on",
      top_6_profile_mistakes_key1_next: " than a resume first read.",
      top_6_profile_mistakes_key2:
        "60% of employers form a first impression of the resume on ",
      top_6_profile_mistakes_key_span: "appearance alone.",
      top_6_profile_mistakes_list: "see List",
      grammar_guidelines: " Grammar Guidelines",
      grammar_guidelines_point1:
        "If most of the bullets are complete sentences, use periods. More importantly, be consistent within each section.",
      grammar_guidelines_point2: "Single space after periods.",
      grammar_guidelines_point3:
        " Book titles/magazines in italics; dissertations/articles in quotes.",
      grammar_guidelines_point4: "Latin honors are lowercase and italicized: ",
      grammar_guidelines_point4_span:
        "cum laude, summa cum laude, magna cum laude.",
      grammar_guidelines_point5:
        "Use of hyphens: if words have become common usage, they don’t need hyphens (Ex: email, online, offline, nonprofit).",
      grammar_guidelines_point6:
        "Do not use an Oxford comma before the “and” in a series (“Presented to CEO, CFO and COO on numerous occasions”). These are concise bullet points, not complicated thesis statemens.",
      grammar_guidelines_point7:
        "Use “en” dash between dates, without spaces (2015-2018).",
      grammar_guidelines_point8:
        "Use an “em” dash when joining phrases (“contrasting architectural styles—both neoclassical and Bauhaus were employed”), without spaces.",
      grammar_guidelines_point9:
        "Use of numbers: One through nine are spelled out, 10 and above should be display as numerals (10, 11, 12). In the same sentence, however, there must be parallel construction such that all the numbers are either spelled out or in digits (unlike the previous sentence).",
      grammar_guidelines_point10:
        "Monetary figures: K for thousands ($14K), M for million ($20M), B for billion ($50B)",
      grammar_guidelines_point11:
        "Job titles: use Upper Case for titles in headings; if title appears within the body text section of profile, do not capitalize.",
      grammar_guidelines_point12:
        "Ampersand (&): do not use unless in formal name of company (Pei Cobb Freed & Partners).",
      grammar_guidelines_point13:
        "Email addresses: all in lowercase and not underlined.",
      grammar_guidelines_point14:
        "Fonts: the fewer font sizes and type treatments the better; font size should be the same in the body text of the entire profile.",
      grammar_guidelines_point15:
        " Do not underline words in headings & subheadings.",
      grammar_guidelines_point16:
        "Do not use periods with diploma degrees (BArch, MArch, BS).",
      PARS_method: "Use PARS Method for Work Experience",
      PARS_method_highlight: "Highlight:",
      PARS_method_highlight_p1: "roblem you addressed",
      PARS_method_highlight_p2: "ction you took",
      PARS_method_highlight_p3:
        "esults, best if use quantifiable numbers (most important)",
      PARS_method_highlight_p4: "kills you gained (least important)",
      PARS_method_point1: "Do not write job description!",
      PARS_method_point2: "Write most significant bullets first.",
      PARS_method_point3:
        "Don’t confuse time-consuming activities with achievements.",
      PARS_method_point4:
        "Context can add strengths: resource constraints, deadlines, budgets, increasing market share, etc. Example: “Managed and led a team of 6 in 100-person office redesign: met deadlines, finished 5% under budget and increased space utilization 12%.”",
      active_verbs_function: "Action Verbs Listed by Function",
      active_verbs_function_point1: "Client Management Skills",
      active_verbs_function_point2: "Financial and Project Management Skills",
      active_verbs_function_point3: "Marketing & Development Skills",
      active_verbs_function_point4: "Technical Skills",
      active_verbs_function_point5: "Project Assistant Skills",
      active_verbs_function_point6: "Design Skills",
      active_verbs_function_point7: "Junior Level and Detail Work",
      active_verbs_function_point8: "Organizational & Mentoring Skills",
      active_verbs_function_point9: "Project Management Skills",
      active_verbs_function_link: "See Alphabetical List",
      active_verbs_alphabetical: "Action Verbs Listed Alphabetically",
      specific_language_coaching: "Specific Profile Language Coaching",
      specific_language_coaching_point1: "Be articulate and concise.",
      specific_language_coaching_point2:
        "Fact-based is always better — quantify!",
      specific_language_coaching_point3:
        " Written for people who scan quickly. It has been well documented that most busy professionals and hiring managers spend less than 6 seconds on first read.",
      specific_language_coaching_point4:
        "Use sentence fragments (”a” and “the” generally not needed).",
      specific_language_coaching_point5:
        "Order bullets by what is most important to the a hiring manager or industry contact, not by time put in.",
      specific_language_coaching_point6:
        "First 3-4 words of bullet most important given the inevitable skim.",
      specific_language_coaching_point7: "No personal pronouns (not “I”).",
      specific_language_coaching_point8:
        "No abbreviations or jargon unless industry standard (such as CAD). Otherwise, use full words or layman’s term first (acronym/technical term may be in parenthesis).",
      specific_language_coaching_point9: "Maximum 4 bullets per experience.",
      specific_language_coaching_point10:
        "Maximum 3 lines and 1-2 skills for each bullet.",
      specific_language_coaching_point11:
        "More powerful: “Designed and managed 20-person crew in development of a $10M, 30,000 square foot Vail ski chalet, overcoming rocky substrate.” Rather than, “Overcoming a rock substrate, designed and managed 20-person...”",
      specific_language_coaching_point12:
        "Avoid repeating Action Verbs, certainly not consecutively.",
      specific_language_coaching_point13:
        "Avoid ambiguous words like “successfully” or “very” — by whose standards?",
      specific_language_coaching_point14:
        "Do not misrepresent your experiences, accomplishments or results. You are only as good as your reputation for integrity, and it will catch up with you. The trade community is very small and tight.",
      specific_language_coaching_info: "Bottom Line",
      specific_language_coaching_info_content:
        "Could someone else write that same bullet?",
      specific_language_coaching_info_suncontent:
        "If so, you need to rewrite it!",
      skills_summary_guidelines: "Skills Summary Guidelines",
      skills_summary_guidelines_content1:
        "An online summary can be helpful to entice the viewer and to effectively state your objectives. It is especially recommended if you have 10+ years job experience or you are seeking a very specialized or technical job. Summaries are often used online but are not recommended for paper resumes, where real estate is a premium, and space is better used for active job experiences.",
      skills_summary_guidelines_example1: "Example 1: 10+ Years Experience",
      skills_summary_guidelines_content2:
        "Proven and percision-focused project manager with 12 years success in largescale commercial project. Successful track record in designing, planning, and managing teams both domestically and internationally. Excellent organization, communication and design skills. Yale MArch. Dartmouth BA.",
      skills_summary_guidelines_example2: "Example 2: Specialization",
      skills_summary_guidelines_content3:
        "Experienced AutoCAD Designer. with 8+ years experience. Specialization in detailed residential designs. Leading edge expertise in Naviswork interface, photorealistic rendering and Mtext Objects. Demostrated competence in managing design teams, installin new version and training. Also proficient in Vectorworks. ",
      skills_summary_guidelines_example4: "Example 3: Partnership Bid",
      skills_summary_guidelines_content4:
        "Senior Architect seeking two partners to create a boutique residential firm",
      skills_summary_guidelines_content3_1:
        "Proven carriage-trade clientele: Park Avenue to Beverly Hills.",
      skills_summary_guidelines_content3_2:
        "Record of success in traditional and transitional design with 15 years of experience managing budgets ranging from $200,000 to $30M.",
      skills_summary_guidelines_content3_3:
        "Looking to create joyful, interactive and innovative partnership of three.",
      // skills_summary_guidelines_content3_4:
      //   "",
      additional_interests_guidelines: "Additional Interests Guidelines",
      additional_interests_guidelines1:
        "Designed to create a good first impression",
      additional_interests_guidelines2:
        " and offer possible common ground with your new contacts or interviewers. Also is an effective way to send a message about you, such as your commitment to excellence, teamwork, well-roundedness, intellectual curiosity or sophistication. However, don’t overdo it! Don’t signal that your multiple passions may overwhelm your day job.",
      additional_interests_guidelines3: "This section may include: ",
      additional_interests_guidelines4: " Personal Interests & Affiliations",
      additional_interests_guidelines5: "Charitable Activities",
      additional_interests_guidelines6: "Certificates & Awards",
      additional_interests_guidelines7: "Publications & Patents",
      additional_interests_guidelines8: "Work Authorization",
      additional_interests_guidelines9: "Computer skills",
      additional_interests_guidelines10:
        "Or these may be written as separate categories, depending upon your strengths and chosen profile format. Be specific. “Play classical violin” much better than “Music.” “Hiked Mt. Kilimanjaro” better than “Hiker.” Do not include personal or family information (marital status, birth date, gender).",
      additional_interests_Examples:
        "Examples of Effective Additional Information:",
      additional_interests_Examples_span1: "Languages:",
      additional_interests_Examples_content1:
        " Fluent in French, conversational in Spanish",
      additional_interests_Examples_span2:
        "Licenses & Professional Memberships:",
      additional_interests_Examples_content2: " FAIA, NY and FL ",
      additional_interests_Examples_span3: "Licensed Architect",
      additional_interests_Examples_content3:
        ", LEED AP BDAC, Professional Member ICAA",
      additional_interests_Examples_span4: "Interests:",
      additional_interests_Examples_content4:
        " Hiked Mt. Kilimanjaro, play classical violin",
      extracurricular_guidelines: "Extracurricular Guidelines",
      extracurricular_guidelines_subheading: "Should I Show Extracurriculars?",
      extracurricular_guidelines_distance: "The Distance Rule:",
      extracurricular_guidelines_content1:
        "If a current student or recent graduate, it is appropriate to include collegiate extracurricular activities, especially those that display leadership, passion, group memberships and academic honors. These can act as signals to future employers about your personality & generate interview questions. ",
      extracurricular_guidelines_content2:
        "However, if you graduated two years ago or more, just show academic honors. Only include GPAs of 3.0+ (“3.0 good to go!”) and only to one decimal place. Your strong or compelling collegiate activities may be displayed in the Additional Interest section, if not in the Education section.",
      extracurricular_guidelines_content_1: "Good Points to Highlight:",
      extracurricular_guidelines_content_2:
        " Traveling A Club Squash Team, President - Architecture Club Fraternity Rush Chair",
      extracurricular_guidelines_Example: "Example Education Highlights ",
      extracurricular_guidelines_Bullet1: "Line 1:",
      extracurricular_guidelines_Bullet1_content:
        " BA, Statistics major, Economics minor ",
      extracurricular_guidelines_Bullet1_or: "~OR~",
      extracurricular_guidelines_Bullet1_extra1:
        " Earned xx/yy credits toward a BA, Accounting (Degree, Major)",
      extracurricular_guidelines_Bullet2: "Line 2:",
      extracurricular_guidelines_Bullet2_content1:
        " Dean’s List, GPA 3.5 (Academics, Honors or other Honorifics)",
      extracurricular_guidelines_Bullet3: "Line 3:",
      extracurricular_guidelines_Bullet3_content1:
        "Traveling A Club Squash Team, President - Architecture Club, Fraternity Rush Chair (Extracurriculars)",
      action_verbs_Alphabetical: "Action Verbs, Alphabetical",
      action_verbs_functional: "Action Verbs, by Function",
      Client_Management_Skills: "Client Management Skills",
      Design_Skills: "Design Skills",
      Financial_Project_Management_Skills:
        "Financial & Project Management Skills",
      Junior_Level_Detail_Work: "Junior Level & Detail Work",
      Marketing_Development_Skills: "Marketing & Development Skills",
      Organizational_Mentoring_Skills: "Organizational & Mentoring Skills",
      Project_Assistant_Skills: "Project Assistant Skills",
      Project_Management_Skills: "Project Management Skills",
      Technical_Skills: "Technical Skills",
      Language_Skill_Levels: "Language Skill Levels",
      Language_Skill_Levels_sum: "Your Comfort Level",
      Language_Skill_Levels_sum_Rating: "Rating",
      Language_Skill_Levels_level1: "Level 1:",
      Language_Skill_Levels_level1_content: " phrases, sentences",
      Language_Skill_Levels_level2: "Level 2:",
      Language_Skill_Levels_level2_content:
        " Basic conversations, functioning, intermediate",
      Language_Skill_Levels_level3: "Level 3:",
      Language_Skill_Levels_level3_content:
        " Fluid conversations, candiscuss current events, advanced",
      Language_Skill_Levels_level4: "Level 4:",
      Language_Skill_Levels_level4_content:
        " Expert speaker, can articulate gracefully",
      Language_Skill_Levels_level5: "Level 5:",
      Language_Skill_Levels_level5_content: " First or primary language",
      Basic: "Basic",
      Functional: "Functional",
      Conversational: "Conversational",
      Fluent: "Fluent",
      Native: "Native",
    },
    messages: {},
    errors: {},
  },
  skills: {
    headingStyle1: {
      general_skills: "Trade:",
      work_authorization: "Trade:",
      design_proclivities: "Trade:",
      personality_traits: "Trade:",
      trade_industry_experties: "Trade:",
      business_skills: "Trade:",
      language: "Trade:",
      creative_skills: "Trade:",
      clientele_experience: "Trade:",
      computer_skills: "Trade:",
    },
    headingStyle2: {
      general_skills: "All",
      work_authorization: "All",
      design_proclivities: "All",
      personality_traits: "All",
      trade_industry_experties: "ARCHITECT",
      business_skills: "ARCHITECT",
      language: "ARCHITECT",
      creative_skills: "ARCHITECT",
      clientele_experience: "ARCHITECT",
      computer_skills: "ARCHITECT",
    },
    title: {
      general_skills: "GENERAL SKILLS",
      work_authorization: "WORK AUTHORIZATION",
      design_proclivities: "DESIGN PROCLIVITIES",
      personality_traits: "PERSONALITY TRAITS",
      trade_industry_experties: "TRADE/INDUSTRY EXPERTISE",
      business_skills: "BUSINESS SKILLS",
      language: "LANGUAGES",
      creative_skills: "CREATIVE SKILLS",
      clientele_experience: "CLIENTELE EXPERIENCE",
      computer_skills: "COMPUTER SKILLS",
    },
    info: {
      info_img: {
        general_skills: "true",
        work_authorization: "false",
        design_proclivities: "true",
        personality_traits: "true",
        trade_industry_experties: "false",
        business_skills: "false",
        language: "false",
        creative_skills: "false",
        clientele_experience: "false",
        computer_skills: "false",
      },
      info_part1: {
        general_skills: "To add skills ",
        work_authorization: "In which countries are you authorized to work",
        design_proclivities: "To add skills ",
        personality_traits: "To add skills ",
        trade_industry_experties:
          "Add skills with the plus icon. \n Click on gray dots to rate. Hover on green checkmark to delete",
        business_skills:
          "Add skills with the plus icon. \n Click on gray dots to rate. Hover on green checkmark to delete",
        language: "Add language to your profile by clicking on the plus icon.",
        creative_skills:
          "Add skills with the plus icon. \n Click on gray dots to rate. Hover on green checkmark to delete",
        clientele_experience:
          "Add skills with the plus icon. \n Click on gray dots to rate. Hover on green checkmark to delete",
        languages: "My languages",
      },
      info_part2: {
        general_skills: ". To delete, hover on",
        design_proclivities: ". To delete, hover on",
        personality_traits: ". To delete, hover on",
      },
      info_part3: {
        general_skills:
          ". Add a new skill at the bottom of the search drop down (to avoid redundancies!)",
        design_proclivities:
          ". Add a new skill at the bottom of the search drop down (to avoid redundancies!)",
        personality_traits:
          ". Add a new skill at the bottom of the search drop down (to avoid redundancies!)",
      },
      all_skills: {},
      selected_skills: {
        general_skills: "My Skills",
        design_proclivities: "My Traits",
        personality_traits: "My Traits",
        work_authorization: "Authorized",
        trade_industry_experties: "My Skills",
        creative_skills: "My Skills",
        business_skills: "My Skills",
        clientele_experience: "My Skills",
        languages: "My languages",
        computer_skills: "My Skills",
      },
      all_skills: {
        general_skills: "Skill Bank",
        design_proclivities: "Trait Bank",
        personality_traits: "Trait Bank",
        work_authorization: "Country",
        trade_industry_experties: "Skill Bank",
        creative_skills: "Skill Bank",
        business_skills: "Skill Bank",
        clientele_experience: "Skill Bank",
        languages: "All Languages",
        computer_skills: "All Skills",
      },
    },
  },
  Social_media: {
    Sharing_caring: {
      title: {
        Sharing_caring: "Sharing is caring",
        Sharing_caring_subHeading: "Social Media Feeds",
        Sharing_caring_des:
          "Engage prospective candidates with your style and ongoing successes",
        Sharing_connect: "CONNECT",
        Sharing_update: "UPDATE",
      },
    },
    highlight_choices: {
      title: {
        highlight_choices: "HIGHLIGHT CHOICES",
        highlight_choices_des:
          "Choose One Institution & One Experience to Highlight",
        highlight_choices_Institutions: "Your Institutions",
        highlight_choices_Institutions1:
          "Harvard University Graduate School of Design",
        highlight_choices_Institutions2: "Fashion Institute of Technology",
        highlight_choices_Institutions3: "Parson’s Art School",
        highlight_choices_Experience: "Your Job Experience",
        highlight_choices_Experience1: "Armstrong Design",
        highlight_choices_Experience2: "Parsely & Chase Designs",
      },
    },
    first_impression: {
      title: {
        first_impression: "First impressions",
        first_impression_des:
          "Any edits here will be reflected in your overall TradeWorks Settings",
        first_impression_FN: "First Name",
        first_impression_LN: "Last Name",
        Headline: "Headline",
        Headline_des: "This will override all other Headline fields",
        Headline_detail:
          "Make sure your Headline matches the corresponding Education and Experience tiles!",
        Trade: "MAIN Trade",
        Level: "Level",
        city: "City, State or ZIP",
        Add_Trade: "Add Trade",
        Flipbook: "My Flipbook",
        Flipbook_des: "Link one to your profile",
        looks_good: "LOOKS GOOD",
        not_usa: "Not USA?",
      },
    },
    professional_summary: {
      title: {
        tell_me: "TELL ME ABOUT YOU",
        professional_summary: "Professional Summary",
        professional_summary_placeholder:
          "Proven and percision-focused project manager with 12 years success in large-scale commercial project. Successful track record in designing, planning, and managing teams both domestically and internationally. Excellent organization, communication and design skills. Yale MArch. Dartmouth BA.",
        Accolades: "Accolades",
        Accolades_sub:
          "Add Accolade from the list below, or create your own at the end",
        update: "UPDATE",
      },
    },
    resume: {
      title: {
        resume: "YOUR RESUME",
        resume_des: "A good old-fashioned resume is also very important",
        Url: "Elevator Pitch URL",
        creating_Elevator: "Best practices in creating your Elevator Pitch >",
        my_resume: "My Resume",
        see_all: "See All",
        PDFs: "PDFs",
        Upload_New: "Upload New",
        Resume_Wizard: "Resume Wizard",
        Create_New: "Create New",
        Yellow_Resume: "Yellow Resume",
        Franklin_Resume: "Franklin Resume",
        good_job: "GOOD JOB",
      },
    },
  },
  media_file: {
    title: {
      description: "<File Description>",
    },
  },
  notables: {
    titles: {
      notableHeading: "• Bragging RIGHTS • ",
      notableSub: "NOTABLES",
    },
    labels: {
      notableTitle: "Title",
      titlePlaceHolder: "Why Art Thrives at Burning Man",
      institutionOrPublisher: "Institution/Publisher",
      institutionOrPublisherPlaceHolder: "Ted Talks",
      Date_released: "Date Released",
      url: "Related URL",
      details: "Details",
      descriptionPlaceHolder:
        "The well-worn charms of these summer destinations—Kennebunkport, Maine; Nantucket, Massachusetts; and Newport, Rhode Island. The well-worn charms of these summer destinations—Kennebunkport, Maine; Nantucket, Massachusetts; and Newport, Rhode Island.",
      saveNotableButton: "SAVE NOTABLE",
      updateNotableButton: "UPDATE NOTABLE",
      postButton: "POST",
      addNotableTile: "Add Notable Tile",
    },
    errors: {
      notable_title_required: "Title is required",
    },
  },
  social_media_share: {
    Heading: "SHARE YOUR TW PROFILE",
  },
  passwords: {
    titles: {
      recover_password: "Recover password",
      email_pjn_Error: "Email not recognized",
      get_new_password: "GET NEW PASSWORD",
      Oops: "Oops! I can’t remember my email",
      recover_here: "Recover Here",
      Wait_password: "Wait, I remembered my password!",
      sign_in: "Sign In",
      not_member: "Not yet a member?",
      join_here: "Join Here!",
      enter_new_password: "ENTER NEW PASSWORD",
      enter_password: "Enter Password ",
      set_new_password: "SET NEW PASSWORD",
      set: "You are all set!",
      ok: "ok!",
      find_account: "lets find your account",
      email_or_phone: "Enter Your Email",
      email_phone_not_recognized: "Email not recognized",
      locate: "LOCATE",
      reset_pwd_txt: "RESET PASSWORD",
      got_it: "GOT IT",
      reset_password: "Reset your password",
      set_new_password_via: "Set new password via:",
      test: "Text",
      security_code: "Enter security code",
      text_message:
        "A text message with a 6-digit verification code was just sent to (917) 555-9100",
      get_code: "Didn’t get a code?",
      invalid_otp: "INVALID CODE",
      recover_here_txt:
        "To recover your email or phone credentials, call us at 212.639.9100 or email:",
    },
  },
};

export default strings;
